import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Context } from '../menu-state';
import './menu.scss';

interface MenuItem {
  label: string
  target: string
  state?: any
}

const MENU_ITEMS : MenuItem[] = [
  { label: 'Home', target: '/' },
  { label: 'Gallery', target: '/gallery' },
  { label: 'Exhibitions', target: '/exhibitions' },
  { label: 'Take action', target: '/take-action' },
  { label: 'Submit', target: '/submit' },
  { label: 'About', target: '/about' },
];

const Menu = () => {
  const { isOpen, setIsOpen } = useContext(Context);
  const closeMenu = () => setIsOpen(false);
  return (
    <div className={`menu${isOpen ? ' is-open' : ''}`}>
      <button
        className="menu-close-button"
        type="button"
        onClick={closeMenu}
        title="Close menu"
      >
        Menu

      </button>
      <ul className="menu-list">
        {
          MENU_ITEMS.map(({ label, target }) => (
            <li key={target}>
              <Link to={target} onClick={closeMenu} className="menu-list-link">
                {label}

              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Menu;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-exhibitions-tsx": () => import("./../../../src/pages/exhibitions.tsx" /* webpackChunkName: "component---src-pages-exhibitions-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../../../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */),
  "component---src-pages-take-action-tsx": () => import("./../../../src/pages/take-action.tsx" /* webpackChunkName: "component---src-pages-take-action-tsx" */),
  "component---src-templates-artwork-tsx": () => import("./../../../src/templates/artwork.tsx" /* webpackChunkName: "component---src-templates-artwork-tsx" */)
}


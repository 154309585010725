import React, { useState, createContext } from 'react';

interface MenuContextProps {
  isOpen: boolean
  // eslint-disable-next-line
  setIsOpen?(open: boolean): void;
}

export const Context = createContext({
  isOpen: false,
} as MenuContextProps);

// istanbul ignore next
const ContextProvider = ({ children }: {children: React.ReactNode}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Context.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;

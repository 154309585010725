import React from 'react';
import Menu from './src/components/menu';
import MenuState from './src/components/menu-state';

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => (
  // eslint-disable-next-line
  <MenuState>
    {element}
  </MenuState>
);

export const wrapPageElement = ({ element }) => (
  <>
    <Menu />
    {element}
  </>
);
